import { Injectable } from '@angular/core';

declare global {
  interface Window {
    Telegram: any;
  }
}

@Injectable({
  providedIn: 'root',
})
export class TelegramService {
  private telegram: any;

  init() {
    if (typeof window.Telegram !== 'undefined' && window.Telegram.WebApp) {
      this.telegram = window.Telegram.WebApp;
      console.log('Telegram Web App initialized');
    } else {
      console.error('Telegram Web App not loaded');
    }
  }

  openLink(url: string): void {
    if (this.telegram) {
      this.telegram.openLink(url);
    } else {
      window.open(url, '_blank'); 
    }
  }

  openTelegramLink(url: string): void {
    if (this.telegram) {
      this.telegram.openTelegramLink(url);
    } else {
      console.error('Telegram WebApp is not available');
    }
  }
}
