export const environment = {
  production: false,
  name: 'development',
  projectId: 'iUy5C5xzZrCgrnaIbz3u',
  apiUrl: 'https://us-central1-readymaster-development.cloudfunctions.net',
  auth: {
    url: 'https://webgldemo-dev-oauth.firebaseapp.com/',
  },
  gtag_measurement_id: 'G-RKRXKNV9MC',
};
